.support-message {
  width: 100%;
  padding: 5px 10px;
  margin-top: 10px;
  border-width: 0px;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: #f0ad4e;
}
.support-message-about-text {
  font-size: 14px;
  font-weight: 500;
  color: #848484;
}
.support-message-about-text .support-message-author {
  margin-right: 10px;
}
