.invoicing-saving-text {
    color: #4CAF50;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.invoicing-saving-text span {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    border-width: 2px;
}