body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-bottom: 0px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
/*
background: linear-gradient(110deg, #E6E6E6 8%, #E6E6E6 18%, #dfdfdf 33%);
  border-radius: 20px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
*/

.text-right {
  text-align: right;
}