.settings-subtitle-text {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  color: #666;
}
.install-details-setting {
  display: flex;
  padding: 10px 0px;
  padding-top: 20px;
}
.install-details-setting-about-col {
  flex: 1;
  padding-right: 20px;
}
.install-details-setting-label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.install-details-setting-label-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
.install-details-setting-textbox-col {
  display: flex;
  flex: 1;
  padding-right: 20px;
  flex-wrap: wrap;
  align-content: center;
}
.install-details-save-wrapper {
  padding: 15px 0px;
}
.connections-wrapper {
  padding: 20px 0px;
}
.connection-line {
  display: flex;
  margin-bottom: 10px;
}
.connection-status-col {
  display: flex;
  align-items: center;
}
.connection-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin-right: 10px;
}
.connection-status-succeeded {
  background-color: #4caf50;
}
.connection-status-failed {
  background-color: #f44336;
}
.connection-name-col {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}
.connection-name-col .connection-name-text {
  font-size: 16px;
  font-weight: 500;
}
.connection-status-text {
  font-size: 12px;
  font-weight: 400;
}
.connection-name-text-succeeded {
  color: #4caf50;
}
.connection-name-text-failed {
  color: #2e2e2e;
}
.connection-disconnect-text {
  background-color: transparent;
  border-width: 0px;
  text-decoration: underline;
  color: #375fed;
}
.connect-to-quickbooks-btn-image {
  height: 30px;
}
