.edit-process-top-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}
.edi-process-top-col {
}
.page-edit-processes-tabs {
  width: 100%;
  margin-bottom: 15px;
}
.page-edit-process-tab {
  display: inline-block;
  padding: 5px 20px;
  border-width: 0px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #d8d8d8;
  background-color: transparent;
}
.page-edit-process-tab-current {
  border-bottom-color: teal;
}
