.install-processes-link {
  text-decoration: none;
}
.install-process-top-row {
  width: 100%;
  display: flex;
  align-items: center;
}
.install-process-top-title-col {
}
.install-process-top-actions-col {
  padding-left: 20px;
}
.install-process-top-actions-btn {
  padding: 3px 5px;
  font-size: 16px;
  font-weight: 500;
  border-width: 0px;
  color: #848484;
  background-color: transparent;
  cursor: pointer;
}
.install-processes-item {
  display: flex;
  padding: 10px 0;
}
.install-processes-item-icon-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
}
.install-processes-item-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.install-processes-item-icon i {
  font-size: 20px;
  color: #5c5b5b;
}
.install-processes-item-details-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
.install-processes-item-name {
  font-size: 16px;
  font-weight: 500;
  color: #5c5b5b;
}
.install-processes-item-description {
  font-size: 14px;
  color: #5c5b5b;
}
.install-processes-item-tag-col {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}
.install-processes-item-tag {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  color: #fff;
  background-color: #04b486;
}
