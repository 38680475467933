.install-details-header-row {
  padding: 20px 0px;
  padding-top: 50px;
}
.install-details-top-divider {
  border-top: 1px solid #e5e5e5;
  margin-top: 20px;
}
.back-to-dashboard-link {
  color: #2e2e2e;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}
.back-to-dashboard-link i {
  font-size: 12px;
}
.install-details-title {
  font-size: 32px;
}
.menu-col {
  padding-top: 20px;
}
.menu-item-link {
  color: #2e2e2e;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
  display: block;
}

.page-content-card {
  padding: 20px;
  margin-top: 0px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
  padding-bottom: 50px;
}
.page-content-card h2 {
  font-size: 24px;
}
.page-content-card h3 {
  font-size: 20px;
}
.install-details-divider {
  border-top: 1px solid #e5e5e5;
  margin-top: 20px;
  margin-bottom: 20px;
}

.h2-loading {
  width: 100%;
  height: 24px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  max-width: 250px;
}
