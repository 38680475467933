.page-title-details-col {
  display: inline-block;
  width: 40%;
}
.page-title-actions-col {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.page-title-action-item {
  display: inline-block;
  margin-left: 10px;
}
.page-title-action-upload-wrapper {
  display: flex;
  flex-direction: column;
}
.page-title-download-csv {
  font-size: 12px;
  padding: 0px;
  text-decoration: underline;
  background-color: transparent;
  border-width: 0px;
}
.tabs-row {
  width: 100%;
  border-bottom-width: 2px;
  border-bottom-color: #f1f1f1;
  border-bottom-style: solid;
  padding-top: 20px;
}

.tabs-row button {
  display: inline-block;
  padding: 10px 20px;
  border-width: 0px;
  background-color: transparent;
  top: 1px;
  position: relative;
}
.tabs-row .active-tab {
  border-bottom-width: 2px;
  border-bottom-color: teal;
  border-bottom-style: solid;
}
.pricing-cell {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.pricing-variant-sku {
  font-size: 14px;
  color: #888;
  margin-bottom: 5px;
}
.quantity-entry {
  display: flex;
  align-items: center;
}
.quantity-entry p {
  margin: 0px;
  padding: 0px 25px;
}
.saving-text {
  padding: 0px 25px;
}
.saving-text .spinner-border {
  margin-right: 5px;
  border-width: 2px;
  height: 15px;
  width: 15px;
}
.pricing-save-btn {
  background-color: transparent;
  border-width: 2px;
  border-radius: 5px;
  border-color: #088a29;
  color: #088a29;
  font-weight: 500;
  padding: 2px 15px;
  margin-left: 10px;
}
.pricing-save-btn i {
  margin-right: 5px;
}
.pricing-delete-btn {
  background-color: transparent;
  border-width: 0px;
}

.pricing-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-input-wrapper .form-label {
  margin: 0px;
  margin-left: 10px;
}