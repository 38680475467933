.credit-card-icon {
    height: 30px;
    margin-right: 10px;
}
.btn-delete-card {
    color: #f44336;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 10px;
    border-width: 0px;
    background-color: transparent;
}
.last-four-digits-text {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}