.back-btn {
  font-size: 14px;
  color: #333;
  text-decoration: underline;
  margin-bottom: 10px;
  background-color: transparent;
  border-width: 0px;
}
.back-btn i {
  margin-right: 5px;
}
.pricing-view-help-text {
  font-size: 12px;
  color: #999;
  margin-bottom: 10px;
}
.partner-divider {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 2px;
  background-color: #f0f0f0;
  width: 100%;
}
.partner-divider-no-line {
  margin-top: 20px;
  margin-bottom: 20px;
}
.partner-overview-table {
  width: 100%;
  border-collapse: collapse;
  max-width: 400px;
}
.partner-details-col {
  width: 50%;
  max-width: 150px;
  padding: 10px;
  border: 1px solid #f0f0f0;
  font-size: 12px;
}
.partner-details-value-col {
  padding: 10px;
  border: 1px solid #f0f0f0;
  max-width: 200px;
  font-size: 14px;
  font-weight: 400;
}
