.shared-edi-v1-title-row {
    padding-bottom: 30px;
}
.shared-edi-nav-tabs .nav-item .nav-link {
    font-weight: 500;
}
.shared-edi-v1-logo {
    width: 100%;
    max-width: 60px;
    display: block;
}
.shared-edi-v1-logo img {
    width: 100%;
}
.shared-edi-v1-title {
    display: flex;
    align-items: center;
}
.shared-edi-v1-title-inner {
    margin: 0;
    padding-left: 10px;
}
.shared-edi-v1-title h1 {
    font-size: 24px;
    font-weight: 500;
}
.shared-edi-v1-title-inner p {
    font-size: 14px;
    color: #6c757d;
    font-weight: 500;
}
.pagination-wrapper-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}