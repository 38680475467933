.view-single-settings-wrapper {
  width: 100%;
  display: flex;
  max-width: 600px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: rgb(224, 224, 224);
  justify-content: space-between;
}
.view-single-settings-title-text {
  margin-bottom: 5px;
}
.view-single-settings-title {
  font-size: 16px;
  font-weight: 500;
  color: rgb(33, 33, 33);
  margin-bottom: 10px;
}
.view-single-settings-badge {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
  background-color: #d8d8d8;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
}
.view-single-settings-actions-col {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.view-single-settings-actions-col button {
  border-width: 0px;
  background-color: transparent;
  cursor: pointer;
  color: #2e2e2e;
}
.view-single-settings-details-col {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
}
