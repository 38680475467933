.actions-parent-col {
}
.actions-col {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.sftp-list-user-single-user-outter {
  display: inline-block;
  width: 33%;
}
.sftp-list-user-single-user {
  padding: 20px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  max-width: 400px;
  margin-right: 10px;
  display: flex;
  margin-bottom: 10px;
}
.sftp-list-user-single-user-icon-col {
  display: flex;
  align-items: center;
}
.sftp-list-user-single-user-icon-col i {
  font-size: 24px;
  color: #d8d8d8;
}
.sftp-list-user-single-user-details-col {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 75px);
  padding-right: 10px;
}
.sftp-list-user-single-user-details-username {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.sftp-list-user-single-user-details-date {
  font-size: 14px;
  color: #a0a0a0;
}
.sftp-list-user-single-user-actions-col {
  display: flex;
  align-items: center;
  width: 50px;
}
.sftp-list-user-single-user-actions-col i {
  font-size: 24px;
  color: #d8d8d8;
  cursor: pointer;
}

.sftp-list-empty-users-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 50px 0px;
}
.sftp-list-empty-users-line1 {
  font-size: 16px;
  font-weight: 500;
  color: #a0a0a0;
  margin-bottom: 10px;
}
.sftp-list-empty-users-line2 {
  font-size: 14px;
  color: #a0a0a0;
  margin-top: 0px;
  margin-bottom: 10px;
}
.sftp-list-user-loading-icon {
  width: 24px;
  height: 36px;

  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.sftp-list-user-loading-detials-line1 {
  width: 100%;
  height: 24px;
  margin-bottom: 5px;

  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.sftp-list-user-loading-detials-line2 {
  width: 100%;
  max-width: 120px;
  height: 20px;
  margin-top: 0px;

  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.sftp-list-user-loading-connect-btn {
  width: 132px;
  height: 35px;
  margin-top: 0px;

  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
