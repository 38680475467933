.page-tabs {
  width: 100%;
}
.page-tabs .page-tab {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  border-bottom: 2px solid transparent;
  text-decoration: none;
}
.page-tabs .page-tab-current {
  border-bottom: 2px solid teal;
  color: teal;
}
.page-tabs .page-tab i {
  margin-right: 5px;
}
