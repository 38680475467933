.dashboard-top-row {
  padding-top: 0px;
}
.dashboard-row {
  padding-bottom: 20px;
}
.installs-panel {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
  border-style: solid;
  border-width: 0px;
  border-color: #e0e0e0;
  border-left-width: 1px;
  min-height: 250px;
}
.installs-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.installs-panel h1 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}
.installs-title-loading {
  width: 100%;
  max-width: 200px;
  height: 32px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.installs-sub-title-text {
  font-size: 14px;
  color: #9b9b9b;
  margin-bottom: 10px;
}
.installs-sub-title-text-loading {
  width: 100%;
  max-width: 350px;
  height: 16px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
}
.installs-panel-loading {
  width: 100%;
  max-width: 100%;
  height: 100px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.installs-panel .table {
  border-width: 1px;
  border-color: #e0e0e0;
  border-style: solid;
  width: 100%;
}
.installs-panel-buttons {
}
.dashboard-page-welcome-row {
  padding-bottom: 25px;
}
.dashboard-page-title {
  font-size: 24px;
  font-weight: 600;
}
.dashboard-page-welcome-subtitle-text {
  font-size: 14px;
  color: #9b9b9b;
}
.dashboard-page-title-loading {
  width: 100%;
  max-width: 300px;
  height: 32px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.dashboard-page-welcome-subtitle-text-loading {
  width: 100%;
  max-width: 350px;
  height: 16px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
}
.dashboard-card-value {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  padding-top: 25px;
}
.dashboard-card-metric {
  font-size: 14px;
  color: #9b9b9b;
}
.dashboard-card-label {
  font-size: 14px;
  color: #9b9b9b;
  text-align: center;
  font-weight: 600;
  padding-bottom: 25px;
}