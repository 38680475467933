.add-account-credit-currency {
  max-width: 80px;
  display: inline-block;
  vertical-align: top;
}
.add-account-credit-amount {
  display: inline-block;
  width: calc(100% - 80px - 80px);
}
.add-account-credit-btn {
  display: inline-block;
  max-width: 80px;
  vertical-align: top;
  padding: 10px 15px;
}
.add-account-credit-icon {
  font-weight: 700;
  color: #fff;
}
.add-account-credit-wrapper {
  width: 100%;
  max-width: 300px;
  padding: 10px 0px;
}
