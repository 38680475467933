.filter-card {
  position: absolute;
  /* border: 1px solid rgb(193, 193, 193); */
  /* height: 200px; */
  width: 300px;
  background-color: #eff2f6;
  right: 30px;
  top: 60px;
  z-index: 10;
}
.no-filters-applied-text {
  margin: 20px 0px;
  margin-bottom: 50px;
  text-align: center;
  font-family: 'Roboto', sans-serif;

}
.filter-action-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.filter-options-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.filter-options-key {
  width: 100%;
  max-width: 91px;
}
.filter-options-operator {
  width: 50px;
  text-align: center;
}
.filter-options-value {
  width: 119px;
}
.filter-options-delete {
  width: 42px;
}
/* --- */
.endpoint-selection-results {
  position: absolute;
  top: 90px;
  background-color: white;
  width: 300px;
  right: 20px;
  text-align: left;
  border: 1px solid #eff2f6;
  border-radius: 5px;
}
.endpoint-selection-results-item {
  padding: 10px;
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.endpoint-selection-results-item:hover {
  background-color: #eff2f6;
}
.endpoint-selection-results-item p {
  margin-left: 5px;
}
.endpoint-selection-results-item-last {
  border-bottom-color:#eff2f6;
}
.endpoint-selection-results-item-selected {
  background-color: #eff2f6;
}
.endpoint-selection-results-item-selected:hover {
  background-color: #fff;
}
.filter-options-value-selected-value {
  border-width: 0px;
}
.filter-options-value-selected-value .lucide {
  height: 12px;
  width: 12px;
}