.dropdown-outter-wrapper {
  width: 100%;
  height: 42px;
  padding: 0px 4px;
  border-color: #d8d8d8;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 8px;
  background-color: #fff;
}
.dropdown-inner {
  outline: none;
  width: 100%;
  border-width: 0px;
  height: 20px;
  margin-top: -15px;
  top: -10px;
  position: relative;
}
.dropdown-label {
  font-size: 10px;
  position: relative;
  top: -5px;
  padding: 2px;
}
