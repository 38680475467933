.edit-action-wrapper {
  margin-bottom: 15px;
  max-width: 600px;
}
.edit-action-save-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}
.edit-action-info-row {
  display: flex;
  justify-content: space-between;
  background-color: #d8d8d8;
  padding: 5px 10px;
  border-radius: 5px;
  justify-content: center;
}

.edit-action-info-text-col p {
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
  padding-right: 15px;
}
.arguments-heading {
  font-size: 12px;
  font-weight: 700;
  margin-top: 15px;
}
.arguments-heading-sub-text {
  font-size: 12px;
  color: #848484;
  font-weight: 500;
  margin-bottom: 10px;
}
