.title {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: rgb(26, 31, 54);
}
.title-get {
  color: rgb(135, 146, 162);
}
.logo-wrapper {
  width: 100%;
  text-align: center;
}

.logo-icon {
  height: 35px;
}

.logo-wrapper .logo-image {
  max-width: 100%;
  height: 50px;
}

.logo-wrapper .powered-by {
  font-size: 12px;
  color: rgb(135, 146, 162);
}
.logo-wrapper .powered-by .powered-by-hendricks {
  color: rgb(26, 31, 54);
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}