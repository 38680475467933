#sidebar {
  background-color: #fff;
  box-shadow: none;
  border-right: solid 1px #e0e0e0;
}
.navbar {
  background-color: #fff;
  box-shadow: none;
  border-bottom: solid 1px #e0e0e0;
}
.side-item-indicator {
  background: #3f80ea;
  border-radius: 50%;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.05);
  color: #fff;
  display: block;
  font-size: 0.675rem;
  height: 18px;
  padding: 1px;
  position: absolute;
  left: 10px;
  text-align: center;
  top: -6px;
  transition: top 0.1s ease-out;
  width: 18px;
}
.bottom-side-bar-menu {
  position: absolute;
  bottom: 10px;
}
