.sign-in-row {
  padding-top: 15%;
  padding-bottom: 10%;
}
.sign-in-button-wrapper {
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
}
.sign-in-other-text {
  margin-bottom: 0px;
  font-size: 12px;
}
.verification-instructions-text {
  padding: 10px 15px;
  text-align: center;
  width: 100%;
}
.create-company-instructions-title {
  width: 100%;
  padding-top: 5px;
  font-size: 18px;
}
.create-company-instructions-text {
  width: 100%;
  padding-bottom: 10px;
  font-size: 14px;
}
