.edi-dashboard-h2 {
    font-size: 16px;
    font-weight: 500;
}
.edi-dashboard-right-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.edi-dashboard-right-title {
    font-size: 12px;
    color: rgb(63, 128, 234);
    font-weight: 500;
    margin-left: 5px;
}
.edi-dashboard-right-item {
    margin-bottom: 15px;
}
.edi-dashboard-right-description {
    margin-bottom: 15px;
}
.edi-dashboard-right-code {
    font-size: 12px;
    background-color: #e6e6e6;
    color: #2e2e2e;
    font-weight: 500;
    padding: 3px 5px;
    border-radius: 5px;
}