.page-title-row {
  padding-bottom: 20px;
}
.page-title-row h1 {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin: 0px;
  padding: 0px;
}
