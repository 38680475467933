.textbox-error {
  text-align: left;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 5px 10px;
  margin-bottom: 10px;
  border-radius: 3px;
  font-size: 12px;
}
