.left-menu-white-labelling-partner-status {
  margin: 10px 25px;
  background-color: #fff;
  border: #e0e0e0 1px solid;
  padding: 10px 10px;
  margin: 0px 15px;
  border-radius: 5px;
  display: block;
}
.left-menu-white-labelling-partner-status p {
  font-size: 12px;
  font-weight: 500;
  padding: 0px 0px;
  color: #333;
}
.left-menu-white-labelling-partner-status button {
  margin-top: 10px;
  width: 100%;
}