.onboarding-completed-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 50px;
}
.onboarding-completed-page-title {
    font-size: 24px;
    font-weight: 600;
    color: #4a4a4a;
    margin-bottom: 20px;
}
.onboarding-completed-page-description {
    font-size: 16px;
    color: #4a4a4a;
    margin-bottom: 20px;
    text-align: center;
}