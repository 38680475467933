.alert-message-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
  max-width: 250px;
  display: table;
  background-color: #d8d8d8;
  padding: 10px 15px;
  border-radius: 3px;
}
.alert-message-text {
  font-size: 14px;
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;
}
.alert-message-icon {
  font-size: 24px;
  display: table-cell;
  padding-right: 10px;
  vertical-align: middle;
}
.alert-message-spinner-wrapper {
  display: table-cell;
  padding-right: 10px;
  vertical-align: middle;
}
.alert-message-spinner {
  width: 25px;
  height: 25px;
  border-width: 2px;
}
.alert-message-success {
  background-color: #04b404;
}
.alert-message-icon-success {
  color: #fff;
}
.alert-message-text-success {
  color: #fff;
}
.alert-message-warning {
  background-color: #f7d358;
}
.alert-message-icon-warning {
  color: #6e6e6e;
}
.alert-message-text-warning {
  color: #6e6e6e;
}
.alert-message-error {
  background-color: #b40431;
}
.alert-message-text-error {
  color: #fff;
}
.alert-message-icon-error {
  color: #fff;
}
.alert-message-loading {
}
.alert-message-warning {
}
