.add-pricing-entry-form {
  padding: 10px 20px;
}
.add-pricing-entry-form-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.add-pricing-entry-form-instructions {
  font-size: 14px;
  margin-bottom: 10px;
}
.add-pricing-entry-form-next-btn-wrapper {
  text-align: right;
  margin-top: 20px;
}
.add-pricing-entry-form-product-item {
  width: 100%;
  padding: 10px 0px;
}
.add-pricing-entry-form-product-item-image {
  width: 60px;
  display: inline-block;
}
.add-pricing-entry-form-product-item-details {
  width: calc(100% - 100px);
  display: inline-block;
}
.add-pricing-entry-form-product-item-checkbox {
  width: 40px;
  display: inline-block;
}
.add-pricing-entry-form-product-item-image img {
  width: 50px;
}
.add-pricing-entry-form-product-item-details h3 {
  font-size: 16px;
  margin: 0;
}
.add-pricing-entry-form-variant-item {
  padding: 10px 0px;
  width: 100%;
}
.add-pricing-entry-form-variant-item-checkbox {
  width: 30px;
  display: inline-block;
}
.add-pricing-entry-form-variant-item-details {
  width: calc(100% - 30px);
  display: inline-block;
}
.add-pricing-entry-form-variant-item-details h3 {
  font-size: 16px;
  margin: 0;
}
