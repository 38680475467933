.steps-indicator {
  display: flex;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 0px 0px;
}
.steps-indicator .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 15px;
  border-radius: 10px;
  background-color: #cdcbcb;
  color: #cdcbcb;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  margin-right: 10px;
}
.steps-indicator .active-step {
  background-color: #045fb4;
}
/* --- Install Select Integration --- */
.install-select-integration {
  font-size: 20px;
  margin-bottom: 20px;
}
.install-option-item {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  min-height: 40px;
}
.install-option-selection-col {
  width: 50px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  min-height: 40px;
}
.unselected-option {
  width: 20px;
  height: 20px;
  background-color: #e6e6e6;
  border-radius: 50%;
}
.install-option-details-col {
  width: calc(100% - 50px);
}
.install-option-details-col img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.install-option-details-col .bi {
  font-size: 20px;
  color: #848484;
  padding: 0px 10px;
}
.selected-option {
  width: 20px;
  height: 25px;
  background-color: transparent;
  border-radius: 50%;
}
.selected-option .bi {
  font-size: 20px;
  color: #04b404;
}
.install-next-buttons {
  margin-top: 50px;
}

/* --- Access Tokens Form --- */
.integration-setup-key-wrapper {
  margin-bottom: 20px;
}
.integration-key-title-row {
  margin-bottom: 20px;
}
.integration-key-title {
  font-size: 16px;
  font-weight: 600;
}
.integration-key-title-subtext {
  font-size: 14px;
  color: #848484;
}
.access-tokens-row {
  display: flex;
  margin-bottom: 20px;
}
.access-tokens-col {
  width: 50%;
}
.access-tokens-right-col {
  padding-left: 10px;
}
.access-tokens-left-col {
  padding-right: 10px;
}
.how-to-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
