.create-fulfillment-form {
    width: 100%;
    display: flex;
}
.create-fulfillment-form-left {
    width: 50%;
}
.create-fulfillment-form-right {
    width: 50%;
    padding-left: 40px;
    padding-right: 10px;
    padding-top: 40px;
}
.create-fulfillment-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e5;
}
.create-fulfillment-modal-header-left {
    display: flex;
    flex-direction: column;
}
.create-fulfillment-modal-header-right {
    display: flex;
    align-items: center;
}