.company-selector-wrapper {
  margin: 10px 20px;
  display: inline-block;
}
.company-selector-current-selected {
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #d8d8d8;
  width: 200px;
  cursor: pointer;
  display: flex;
}
.company-selector-details {
  padding-right: 5px;
  top: 2px;
  position: relative;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
}
.company-selector-current-selected-icon {
  width: 30px;
}
.company-selector-icon-wrapper {
  width: 30px;
  background-color: #f1f1f1;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  padding-top: 0px;
  display: inline-block;
  margin-right: 5px;
}
.company-selector-icon-wrapper p {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 700;
  font-family: 'Noto Sans', sans-serif;
  color: #a4a4a4;
}
.company-selector-options-wrapper {
  width: 100%;
  background-color: #fff;
  border-color: #d8d8d8;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  padding: 0px 5px;
  margin-top: 10px;
  position: absolute;
  max-width: 200px;
}
.company-selector-options-item-wrapper {
  padding: 5px 0px;
  border-width: 0px;
  background-color: #fff;
  width: 100%;

  display: flex;
  align-items: center;
}
