.transaction-set-section {
  width: 100%;
  margin-bottom: 40px;
}
.transaction-set-section h2 {
  font-size: 20px;
  font-weight: 500;
  color: #4a4a4a;
}
.title-subtext {
  font-size: 16px;
  font-weight: 500;
  color: #919191;
  margin-bottom: 15px;
}
.transaction-set-dropdown-section {
  width: 100%;
  max-width: 400px;
}
.transaction-set-dropdown-section .support-text {
  font-size: 12px;
  font-weight: 400;
  color: #888787;
  margin-bottom: 15px;
}
.section-divider {
  margin-top: 10px;
  margin-bottom: 30px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #e6e6e6;
}
