.add-api-key-wrapper {
  padding-bottom: 20px;
}
.api-key-listing-item {
  margin-bottom: 10px;
}
.api-key-listing-code {
  width: 100%;
  max-width: 500px;
  margin-bottom: 0px;
}
.api-key-listing-code code {
  display: block;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  font-size: 14px;
  font-family: monospace;
  word-break: break-all;
  color: #333;
}
.api-key-time-since {
  font-size: 12px;
  padding: 0px 10xp;
  color: #848484;
}
