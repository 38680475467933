.section-row {
  padding-bottom: 40px;
}
.section-title {
  font-size: 24px;
  color: #2e2e2e;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.section-title-subtext {
  font-size: 14px;
  color: #848484;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
}
.edi-retailer-logo-row {
  padding-bottom: 50px;
}
.edi-retailer-logo {
  height: 100px;
}
.edi-retailer-details {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}
.edi-retailer-details-key-col {
  font-size: 12px;
  color: #666;
  margin-right: 10px;
  font-family: 'Roboto', sans-serif;
}
.edi-retailer-details-value-col {
  font-size: 16px;
  color: #666;
  font-family: 'Roboto', sans-serif;
}
.edi-retailer-status {
  font-size: 12px;
  color: #666;
  margin-right: 10px;
  background-color: #d8d8d8;
  border-radius: 10px;
  padding: 4px 10px;
  font-weight: 700;
}
.edi-retailer-status-active {
  background-color: #00a65a;
  color: white;
}
.status-next-step {
  font-size: 12px;
  color: #666;
  margin-right: 10px;
  border-radius: 10px;
  padding: 4px 10px;
  font-weight: 700;
}
.edi-retailer-details-value-col a {
  color: #2e2e2e;
  font-weight: 500;
}
.has-been-deleted {
  background-color: #f2dede;
  color: #a94442;
  padding: 2px 8px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 12px;
}
.edi-retailer-details-help-text {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
}
