.order-rules-loading-title {
    width: 100%;
    max-width: 300px;
    height: 30px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}
.order-rules-loading-back-btn {
    width: 100%;
    max-width: 150px;
    height: 20px;
    border-radius: 5px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}
.loading-condition-title {
    width: 100%;
    max-width: 100px;
    height: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}
.loading-condition-children {
    width: 100%;
    max-width: 300px;
    height: 30px;
    margin-bottom: 10px;
    border-radius: 5px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}