.partners-list-wrapper {
  padding-right: 30px;
}
.search-input-box {
  width: 100%;
  max-width: 300px;
  margin-bottom: 25px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}
.search-input-box .bi {
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  width: 17px;
  color: #d8d8d8;
}
.search-input-box input {
  display: inline-block;
  border: none;
  width: calc(100% - 32px);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
.partners-list {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-top: 15px;
}
.partner-list-item {
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 20px;
}
.partner-list-item .partner-logo {
  width: 50px;
  display: inline-block;
}
.partner-list-item .partner-logo .placeholder-img {
  height: 50px;
  width: 50px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.partner-list-item .partner-logo img {
  height: 50px;
  width: 50px;
}
.partner-list-item .partner-details {
  display: inline-block;
  width: calc(100% - 50px);
  padding: 0px 15px;
}
.partner-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0px;
  padding: 0px;
}
.placeholder-partner-name {
  height: 20px;
  width: 100px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}

.empty-partners {
  text-align: center;
  padding: 50px 50px;
}
.empty-partners p {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin: 0px;
  padding: 0px;
}
