.shopify-url-textbox-wrapper {
}
.your-shopify-url-label {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    display: inline-block;
    padding-right: 10px;
}
.shopify-url-inner {
    display: inline-block;
}
.shopify-url-inner input {
    height: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 400;
}
.myshopify-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    padding-left: 5px;
}