
.shopify-connected-row {
    padding: 100px 0px;
}
.shopify-connected-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: rgb(26, 31, 54);
    font-size: 24px;
}
.shopify-connected-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #585858;
    font-size: 14px;
    margin-top: 0px;
}
.shopify-pass-code-textbox-wrapper {
    margin-top: 20px;
}
.shopify-pass-code-help-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #585858;
    font-size: 12px;
    margin-top: 0px;
}
.shopify-connected-redirect {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shopify-connected-redirect p {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #73747b;
    font-size: 14px;
    margin-top: 0px;
    margin-left: 10px;
}
.shopify-connected-redirect .spinner-border {
    width: 20px;
    height: 20px;
    border-width: 3px;
}
.shopify-connected-select-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: rgb(129, 131, 143);
    font-size: 14px;
    margin-bottom: 10px;
}
.select-company-button {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    border-radius: 3.2px;
    border-width: 1px;
    border: 1px solid transparent; /* Initial border setup */
    border-top-color: rgb(222, 230, 237);
    border-left-color: rgb(222, 230, 237);
    border-right-color: rgb(222, 230, 237);
    border-bottom-color: rgb(222, 230, 237);
    background-color: rgb(255, 255, 255);
    color: rgb(108, 117, 125);
    border-image-repeat: stretch;
    box-sizing: border-box;
    padding: 0.25rem 0.7rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    background-clip: padding-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 10px;
    margin-bottom: 15px;
}
.select-company-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-color: rgb(222, 230, 237);
    border-radius: 50%;
    color: rgb(108, 117, 125);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.select-company-name {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: rgb(108, 117, 125);
    font-size: 14px;
    margin-top: 0px;
    text-decoration: none;
}
.shopify-connected-or-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #585858;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.shopify-connected-create-company-button {
    width: 100%;
    display: flex;
    border-radius: 3.2px;
    border-width: 1px;
    border: 1px solid transparent; /* Initial border setup */
    border-top-color: rgb(222, 230, 237);
    border-left-color: rgb(222, 230, 237);
    border-right-color: rgb(222, 230, 237);
    border-bottom-color: rgb(222, 230, 237);
    background-color: rgb(255, 255, 255);
    color: rgb(108, 117, 125);
    border-image-repeat: stretch;
    box-sizing: border-box;
    padding: 0.25rem 0.7rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
    background-clip: padding-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 10px;
    margin-bottom: 15px;
    text-decoration: none;
}