.main-button {
  padding: 5px 25px;
  font-size: 14px;
  border-width: 0px;
  border-radius: 5px;
  background-color: teal;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}
.main-button-disabled {
  background-color: #ccc;
  color: #fff;
}
.main-button:hover {
  color: #fff;
}
.main-button-spinner {
  width: 15px;
  height: 15px;
  border-width: 2px;
  margin: 0px 10px;
  color: #fff;
}
.button-link {
  padding: 7px 25px;
  font-size: 14px;
  border-width: 0px;
  border-radius: 5px;
  background-color: teal;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
}
.button-link:hover {
  color: #fff;
}
