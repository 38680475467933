.product-validation-check {
  padding: 20px 0px;
}
.product-validation-check i {
  font-size: 24px;
  position: relative;
  top: 3px;
}
.product-validation-check-passing {
  color: #4caf50;
}
.product-validation-check-failing {
  color: #f44336;
}
.product-validation-check span {
  font-size: 16px;
}
.product-validation-check button {
  margin-left: 10px;
  background-color: #d8d8d8;
  border: none;
  color: #2e2e2e;
  padding: 3px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}
