.info-banner {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  background-color: #e6e6e6;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
}
.info-icon-col {
  text-align: center;
}
.info-icon-col .bi {
  font-size: 24px;
  color: #4a4a4a;
}
.info-text-col {
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Noto Sans', sans-serif;
}
.objects-created-section h2 {
  font-size: 20px;
  font-weight: 500;
  color: #4a4a4a;
}
.objects-created-title-sub-text {
  font-size: 16px;
  font-weight: 500;
  color: #919191;
  margin-bottom: 15px;
}
.objects-create-action-btns-row {
  margin-bottom: 20px;
}
.objects-create-action-btns-row button {
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  border-width: 0px;
  border-radius: 5px;
  background-color: #e6e6e6;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 5px;
}
.objects-create-action-btns-row button:hover {
  background-color: #dfdfdf;
}
.objects-create-action-btns-row button .bi {
  font-size: 14px;
  margin-right: 5px;
}
.save-edi-path-btn .bi {
  font-size: 24px;
}
