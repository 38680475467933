.process-editor-top-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.process-editor-top-details-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.process-editor-top-actions-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}
