.domain-instructions {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    max-width: 400px;
    width: 100%;
    margin-top: 10px;
}
.domain-instructions p {
    margin-bottom: 10px;
}
.domain-verified {
    color: #4caf50;
    margin-left: 55px;
    font-weight: 500;
}
.domain-verified svg {
    margin-right: 2px;
}
.white-labelling-settings-domain {
    font-size: 14px;
    color: #333;
}
.white-labelling-settings-domain code {
    background-color: #f5f5f5;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 13px;
    color: #333;
    padding: 3px 5px;
}
.white-labelling-settings-section-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 10px;
}
.logo-form-group {
    margin-top: 10px;
    margin-bottom: 5px;
    max-width: 230px;
}
.white-labelling-settings-logo {
    height: 50px;
}