.invite-partner-form {
  border-radius: 5px;
  outline: none;
  border-radius: 5px;
  padding: 10px 20px;
}
.invite-partner-form h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}
.invite-partner-form .subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
