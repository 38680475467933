.view-product-title-col {
  width: 50%;
  display: inline-block;
}
.view-product-actions-col {
  width: 50%;
  display: inline-block;
  text-align: right;
  padding-right: 25px;
}
.view-product-title-col .back-btn {
  font-size: 14px;
  color: #333;
  text-decoration: underline;
  margin-bottom: 10px;
  background-color: transparent;
  border-width: 0px;
}
.view-product-title-col .back-btn i {
  margin-right: 5px;
}
.product-page-panel {
  margin-bottom: 30px;
}
.product-page-panel h2 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #848484;
}
.product-page-panel .bottom-title-spacer {
  margin-bottom: 10px;
}
.product-page-description {
  font-size: 16px;
  color: #333;
}

/* ---- Variants --- */
.variants-table {
  width: 100%;
}
.variants-table th {
  font-size: 14px;
  font-weight: 600;
  color: #848484;
}
.variant-details-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.variant-details-inner-cell {
  padding-left: 10px;
}
.variant-thumbnail {
  width: 50px;
  height: 50px;
}
.variant-title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  width: 100%;
  display: block;
  padding: 0px !important;
}
.variant-sku {
  font-size: 12px;
  color: #333;
  width: 100%;
  display: block;
  padding: 0px !important;
}
.variant-barcode {
  font-size: 12px;
  color: #333;
  width: 100%;
  display: block;
  padding: 0px !important;
}
.variant-quantity {
  font-size: 16px;
  color: #333;
  width: 100%;
  display: block;
  padding: 0px !important;
}
.show-by-location-btn {
  font-size: 12px;
  color: #333;
  text-decoration: underline;
  width: 100%;
  display: block;
  padding: 0px !important;
  border-width: 0px;
  border-bottom-width: 0px !important;
  max-width: 140px;
  text-align: left;
  background-color: transparent;
}
.variant-warehouse-list-item {
  width: 100%;
  display: flex;
  padding: 0px !important;
}
.variant-warehouse-list-item .location-name {
  font-size: 12px;
  color: #333;
  display: inline-block;
  padding: 0px !important;
  background-color: transparent;
  border: none;
}
.variant-warehouse-list-item .location-quantity {
  font-size: 12px;
  color: #333;
  padding: 0px !important;
  margin-left: 5px;
}
.price-quantity {
  margin-right: 5px;
  font-size: 16px;
  color: #333;
}
.price-amount {
  font-size: 16px;
  color: #333;
}

/* --- Product Images --- */
.product-image-btn {
  background-color: white;
  border: 1px solid #e0e0e0;
  margin-right: 10px;
}

.product-image-btn img {
  height: 150px;
}

/* --- Product Attributes --- */

/* --- Details --- */

.product-status-tag {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0px;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 5px;
  display: inline-block;
}
.product-status-active {
  background-color: #dff0d8;
  color: #3c763d;
}
.product-status-inactive {
  background-color: #f2dede;
  color: #a94442;
}
.product-status-selected {
  background-color: #d9edf7;
  color: #31708f;
}
.product-status-reason {
  font-size: 12px;
  font-weight: 500;
  color: #a4a4a4;
  margin: 0px;
  padding: 0px;
}
.product-page-divider {
  margin-top: 20px;
  margin-bottom: 20px;
  /* border-top: 1px solid #e0e0e0; */
}
.product-field-title {
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  margin-bottom: 0px;
}
.product-field-value {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}
