.edi-retrieval-top-wrapper {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.edi-retrieval-top-details-col {
}
.edi-retrieval-top-action-col {
  text-align: right;
}
.edi-retrievals-top-name-loading {
  width: 100%;
  max-width: 250px;
  height: 32px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-bottom: 15px;
}
.edi-retrievals-top-description-loading {
  width: 100%;
  max-width: 500px;
  height: 70px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-bottom: 15px;
}
.edi-retrievals-top-version-loading {
  width: 100%;
  max-width: 200px;
  height: 32px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-bottom: 15px;
}
.schema-description {
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
}
.schema-version {
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 15px;
}
.schema-tabs-wrapper {
  width: 100%;
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #e6e6e6;
  margin-bottom: 20px;
}
.schema-tabs-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
}
.schema-tabs-button-active {
  border-color: teal;
}
