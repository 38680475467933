.blocked-by-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  max-width: 500px;
  background-color: rgba(255, 255, 0, 0.15);
  border-color: #ffff00;
  border-width: 0px;
  border-left-width: 1px;
  border-style: solid;
  margin-bottom: 30px;
  border-radius: 0px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
}
.blocked-by-icon-col {
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blocked-by-icon-col i {
  font-size: 20px;
  color: #bdbdbd;
  margin: 10px;
}
.blocked-by-details-col {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blocked-by-details-col p {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: left;
}
.blocked-by-details-col .show-more-text {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-top: 0px;
}
.blocked-by-details-toggle-btn {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px;
  background-color: transparent;
  color: #bdbdbd;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
