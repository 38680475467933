.install-sync-event-process-link {
  text-decoration: none;
  cursor: pointer;
}
.install-sync-event {
  padding: 15px;
  border-bottom: 1px solid #f5f5f5;
}
.install-sync-event-highlevel-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.install-sync-event .show-more {
  padding-left: 50px;
}
.install-sync-event-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin-right: 15px;
}
.install-sync-event-icon .bi {
  font-size: 20px;
  color: #585858;
}
.install-sync-event-icon-success {
  background-color: rgba(50, 168, 82, 0.2);
}
.install-sync-event-icon-success .bi {
  color: #4caf50;
}
.install-sync-event-icon-failed {
  background-color: rgba(252, 23, 3, 0.2);
}
.install-sync-event-icon-failed .bi {
  color: #bf1102;
}
.install-sync-event-content-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.install-sync-event-content-title {
  font-size: 16px;
  font-weight: 500;
  color: #585858;
}
.sync-event-time-since {
  font-size: 14px;
  color: #585858;
}

/* Process details */
.install-sync-event-processes {
  display: flex;
  /* flex-direction: row; */
  /* align-items: flex-start; */
  flex-wrap: wrap;
  width: 100%;
  /* margin-top: 15px; */
}
.install-sync-event-process {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px;
  width: 100%;
}
.install-sync-event-process-top-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.install-sync-event-process-icon-col .bi-x-circle-fill {
  color: #bf1102;
}
.install-sync-event-process-icon-col .bi-check-circle-fill {
  color: #4caf50;
}
.install-sync-event-process-icon-col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.install-sync-event-process-name {
  font-size: 16px;
  font-weight: 500;
  color: #585858;
}
.install-sync-event-process-error-wrapper {
  margin-left: 20px;
  background-color: rgba(252, 23, 3, 0.2);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #bf1102;
  max-width: 400px;
}
