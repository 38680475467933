.settings-top-row-name-col {
  display: flex;
  align-items: center;
  width: 50%;
}
.settings-top-row-actions-col {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
}
.settings-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.settings-section-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}
.sections-section-description {
  font-size: 14px;
  margin-bottom: 10px;
  color: #666;
}
