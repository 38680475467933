.create-process-modal {
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
}
.create-process-modal-title h2 {
  font-size: 18px;
  font-weight: 600;
}
.create-process-modal-submit-btn-wrapper {
  margin-top: 10px;
  text-align: right;
}
