.create-order-section-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #848484;
}
.shipping-address-row {
    display: flex;
    justify-content: space-between;
}

/* --- */
.line-item-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #f0f0f0;
}
.line-item-details img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 10px;
}

/* --- */
.suggested-line-item-btn {
    background: #fff;
    border-width: 0px;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    width: 100%;
}
.suggested-line-item-btn:hover {
    background: #f0f0f0;
}
.suggested-line-item-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    padding: 10px 0px;
}
.suggested-line-item-wrapper img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 10px;
}
.suggested-line-item-details-col {
    text-align: left;
    padding: 0px 0px; 
    display: flex;
    flex-direction: column;
    justify-content: center; 
}
.suggested-line-item-details-name {
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin-bottom: 0px;
}
.suggested-line-item-loading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    padding: 0px 15px;
}
.suggested-line-item-loading-image {
    width: 50px;
    height: 50px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}
.suggested-line-item-loading-details {
    margin-left: 10px;
}
.suggested-line-item-loading-name {
    width: 120px;
    height: 10px;
    margin-bottom: 5px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}
.suggested-line-item-loading-brand {
    width: 60px;
    height: 10px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}
.suggested-line-item-actions-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 0px;
}
.suggested-variant-line-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}