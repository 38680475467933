/* ---- Search filters row ---- */
.search-filters-row {
}
.search-filters-actions-col {
  padding: 20px 0px;
}
.serach-filters-select-all-checkbox {
  display: inline-block;
  margin-right: 10px;
}
.search-filters-actions-col button {
  display: inline-block;
  margin-right: 10px;
  background-color: #e6e6e6;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  font-size: 14px;
}

.search-filters-col {
  text-align: right;
  padding-right: 50px;
  vertical-align: middle;
}
.search-filters-search-col {
  display: inline-block;
  vertical-align: middle;
}
.search-input-box {
  width: 100%;
  max-width: 300px;
  margin-bottom: 25px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;

  margin-top: 18px;
}
.search-input-box .bi {
  display: inline-block;
  margin-left: 10px;
  margin-right: 5px;
  width: 17px;
  color: #d8d8d8;
}
.search-input-box input {
  display: inline-block;
  border: none;
  width: calc(100% - 32px);
  padding: 10px;
  border-radius: 5px;
  outline: none;
}
.search-filters-col button {
  background-color: transparent;
  border-width: 0px;
  margin-left: 10px;
}

/* -- Single item listing -- */

.single-product-item {
  border-top: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 10px 0px;
  display: flex;
}
.single-product-select-col {
  width: 50px;
  text-align: center;
  display: flex;
}
.single-product-image-col {
  width: 100px;
  text-align: center;
  display: flex;
}
.single-product-image-col img {
  max-height: 100%;
  max-width: 100%;
}
.single-product-info-col {
  width: calc(100% - 50px - 100px - 150px);
  padding: 20px 20px;
  vertical-align: top;
}
.product-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin: 0px;
  padding: 0px;
}
.vendor-name {
  font-size: 12px;
  font-weight: 500;
  color: #a4a4a4;
  margin: 0px;
  padding: 0px;
}
.product-identifiers {
  font-size: 12px;
  font-weight: 500;
  color: #a4a4a4;
  margin: 0px;
  padding: 0px;
}
.single-product-status-col {
  width: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.single-product-status-row1 {
  display: flex;
}
.single-product-status-row2 {
}
.product-status-tag {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0px;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 5px;
}
.product-status-active {
  background-color: #dff0d8;
  color: #3c763d;
}
.product-status-inactive {
  background-color: #f2dede;
  color: #a94442;
}
.product-status-selected {
  background-color: #d9edf7;
  color: #31708f;
}
.product-status-reason {
  font-size: 12px;
  font-weight: 500;
  color: #a4a4a4;
  margin: 0px;
  padding: 0px;
}

/* -- Pagination -- */
.pagingation-col {
  display: flex;
  justify-content: flex-end;
}