.edit-single-settings-wrapper {
  padding: 10px 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.edit-single-settings-title-text {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.edit-single-settings-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.edit-single-settings-save-changes-btn {
  background-color: #f0f0f0;
  border-width: 0px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
}
.edit-single-settings-section {
  padding: 10px 0px;
}
.edit-single-settings-section-title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.edit-single-settings-list-of-new-schemas {
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-width: 0px;
  border-bottom-width: 2px;
  border-color: #e6e6e6;
  border-style: solid;
}
