.switch-outter-wrapper {
  padding: 5px 7px;
  padding-bottom: 1px;
  display: inline-block;
  width: auto;
}
.switch-outter-btn {
  padding: 0px;
  border-width: 0px;
  border-radius: 10px;
  width: 40px;
  background-color: #f1f1f1;
}
.switch-inner-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.switch-inner-circle-active {
  background-color: #045fb4;
}
.switch-inner-circle-inactive {
  background-color: #d8d8d8;
  margin-left: auto;
}
.switch-label {
  vertical-align: top;
  padding-left: 5px;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
}
