.managed-sftp-server-title-row {
    margin-bottom: 30px;
}
.managed-sftp-server-users-card {
    max-width: 600px;
}
.managed-sftp-server-users-create-users-row {
    display: flex;
    justify-content: space-between;
}
.managed-sftp-server-users-card .card-body p {
    font-size: 12px;
}
.managed-sftp-server-users-card .card-body p code {
    font-size: 14px;
    background-color: #d8d8d8;
    color: #2e2e2e;
    padding: 2px 8px;
    border-radius: 5px;
}
