.create-order-form-title {
    font-size: 16px;
    font-weight: 600;
}
.create-order-form-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.edit-shipping-address-btn {   
    font-size: 12px;
    font-weight: 600;
    border-width: 0px;
    cursor: pointer;
    background-color: transparent;
}
.create-order-form-shipping-title-wrapper {
    display: flex;
    align-items: center;
}
.create-order-form-empty-line-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    font-size: 16px;
    font-weight: 600;
}
.create-order-line-items-line-search {
    max-width: 400px;
    width: 100%;
    padding-top: 10px;
}
.create-order-line-items-product-title {
    font-size: 12px;
    font-weight: 600;
    color: #2e2e2e;
    margin-left: 10px;
}
.create-order-line-items-selected-text {
    font-size: 12px;
    font-weight: 400;
    color: #6E6E6E;
    padding-top: 5px;
    padding-bottom: 10px;
}
.add-selected-wrapper {
    width: 100%;
    padding-top: 10px;
    text-align: right;
}
.add-selected-wrapper .dt-paging {
    display: inline-block;
    margin-right: 10px;
}
.line-item-products-cell {
    display: flex;
    align-items: center;
    padding: 10px 0;
}
.line-item-products-cell img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}