.orders-title-row {
    padding-bottom: 30px;
}
.orders-section-title {
    margin-bottom: 10px;
}
.fulfillment-line-item-wrapper {
    margin-left: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px 15px;
    border-left: 2px solid #e5e5e5;
}
.create-fulfillments-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
}