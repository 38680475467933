.settings-row {
  padding: 0px 0px;
}
.settings-wrapper {
  width: 100%;
  background-color: #fff;
  padding: 15px 0px;
  padding-bottom: 25px;
  border-radius: 5px;
}
.settings-inner-wrapper {
  padding: 10px 0px;
  padding-top: 25px;
}
.settings-save-btn-wrapper {
  width: 100%;
  text-align: left;
  padding: 10px 0px;
}
.settings-section-title {
  font-size: 16px;
}
