.pending-association {
  display: flex;
}
.pending-association-name-col {
  width: calc(100% - 320px);
  display: flex;
  align-items: center;
}
.pending-association-org-col {
  width: 200px;
}
.pending-association-actions-col {
  width: 120px;
  text-align: right;
}
.pending-association-actions-col button {
  padding: 0px;
  border-width: 0px;
  background-color: transparent;
  width: 50px;
  height: 42px;
}
.pending-association-actions-col button i {
  font-size: 24px;
}
.pending-association-actions-col button .bi-check2 {
  color: #0da618;
}
.pending-association-actions-col button .bi-x {
  color: #a60d0d;
}
