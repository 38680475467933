.list-files-top-row {
    padding-bottom: 20px;
}
.list-files-selected-user-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}
.list-files-back-to-users-btn {
    background-color: transparent;
    border: none;
    text-decoration: none;
}
.back-directory-btn {
    background-color: transparent;
    border: none;
    text-decoration: none;
}
.file-label-cell {
    cursor: pointer;
}