.textbox-wrapper {
  width: 100%;
  padding-bottom: 8px;
}
.textbox-outter {
  width: 100%;
  border-width: 1px;
  border-color: #d8d8d8;
  border-style: solid;
  border-radius: 3px;
  padding: 3px 2px;
  height: 42px;
  background-color: #fff;
}
.textbox-outter-wrapper-readonly {
  background-color: #f1f1f1;
}
.textarea-outter {
  min-height: 180px;
}
.textbox-mini-label {
  font-size: 10px;
  position: relative;
  top: -5px;
  padding: 2px;
}
.textbox-inner {
  width: 100%;
  border-width: 0px;
  outline: none;
  position: relative;
  top: 3px;
}
.textbox-inner-with-label {
  width: 100%;
  border-width: 0px;
  outline: none;
  position: relative;
  top: -9px;
}
.textbox-inner-readonly {
  background-color: #f1f1f1;
  color: #a4a4a4;
}
.textbox-helptext {
  font-family: 'Noto Sans', sans-serif;
  font-size: 10px;
  padding-top: 3px;
}
.error-helptext {
  color: #8a0829;
}
.textarea-inner {
  height: 145px;
  resize: none;
  top: -5px;
}
