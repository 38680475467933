.product-title-loading {
  width: 100%;
  max-width: 350px;
  height: 30px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
}
.product-section-title-loading {
  width: 100%;
  max-width: 200px;
  height: 20px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
  margin-bottom: 10px;
}
.product-description-loading {
  width: 100%;
  max-width: 100%;
  height: 90px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
}
.variant-title-loading {
  margin-top: 5px;
  width: 100%;
  max-width: 250px;
  height: 30px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
}
.variant-sku-loading {
  width: 100%;
  max-width: 100px;
  height: 20px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
}
.variant-barcode-loading {
  width: 100%;
  max-width: 100px;
  height: 20px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
}
.variant-quantity-loading {
  width: 100%;
  max-width: 100px;
  height: 30px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
  padding: 5px 0px !important;
}
.variant-price-loading {
  width: 100%;
  max-width: 100px;
  height: 30px;
  border-radius: 5px;
  background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  margin-top: 5px;
  padding: 5px 0px !important;
}
