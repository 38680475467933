.shopify-section {
    padding-bottom: 20px;
    padding-top: 0px;
}
.shopify-item-title {
    font-size: 12px;
    text-transform: uppercase;
    color: #666;
    font-weight: 500;
}
.myshopify-input-wrapper {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    padding: 3px 5px;
}
.myshopify-input-wrapper input {
    border-width: 0px;
    border-radius: 0px;
    padding: 0px;
    outline: none;
    text-align: right;
}