.onboarding-title-row {
    padding-top: 150px;
    padding-bottom: 20px;
}
.onboarding-title-row h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: rgb(26, 31, 54);  
}
.step-link {
    width: 100%;
    display: block;
    color: #BDBDBD;
}
.onboarding-section-title {
    font-size: 18px;
    font-weight: 600;
    color: #585858;
    margin-bottom: 0px;
}
.current-step {
    color: #3f80ea;
}
.completed-step {
    color: #6E6E6E;
}
.onboarding-page-next-buttons {
    padding-top: 30px;
    padding-bottom: 50px;
}
.questionnaire-option {
    cursor: pointer;
}
.questionnaire-option-icon {
    cursor: pointer;
}

.onboarding-title-loading {
    width: 100%;
    max-width: 400px;
    height: 32px;
    border-radius: 5px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}
.onboarding-title-subtext-loading {
    width: 100%;
    max-width: 200px;
    height: 16px;
    border-radius: 5px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
    margin-top: 5px;
    margin-bottom: 20px;
}