.edit-pricing-btn-back {
    padding: 0px;
    border-width: 0px;
    background-color: transparent;
    color: #4a4a4a;
}
.edit-pricing-parent-title {
    color: #3f80ea;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

}
.edit-pricing-download-templates {
    padding: 10px 0px;
}
.edit-pricing-download-templates p {
    color: #4a4a4a;
    font-size: 12px;
    margin: 0px;
}
.download-btn {
    background-color: transparent;
    border: none;
    color: #3f80ea;
    font-size: 12px;
    text-decoration: underline;
    padding: 0px;
}
.edit-pricing-entries-table-header {
    font-size: 12px;
}