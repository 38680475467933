.dollar-textbox-wrapper {
  width: 100%;
  padding-bottom: 8px;
}
.dollar-textbox-mini-label {
  font-size: 10px;
  position: relative;
  top: -7px;
  padding: 2px;
}
.dollar-textbox-inner {
  display: flex;
  vertical-align: top;
  align-items: flex-start;
  flex-direction: row;

  position: relative;
  top: -12px;
}
.dollar-textbox-inner-with-label {
  padding: 0px 2px;
  height: 25px;
  width: 100%;
  border-width: 0px;
  outline: none;
}
.dollar-textbox-currency-btn {
  border-width: 0px;
  height: 42px;
  position: relative;
  top: -14px;
  right: -3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: rgba(63 , 128, 234, 0.1);
  font-size: 14px;
  font-weight: 500;
}
.dollar-textbox-inner-with-label-dollars {
  text-align: right;
}
.dollar-textbox-inner-with-label-cents {
  max-width: 50px;
}
