.checkout-submit-wrapper {
  width: 100%;
  max-width: 500px;
  padding: 15px 0px;
}
.checkout-billing-success-wrapper {
  text-align: center;
  padding-top: 25px;
  max-width: 400px;
}
.checkout-billing-success-icon {
  color: #298a08;
  font-size: 32px;
}
.checkout-billing-success-text {
  color: #298a08;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  padding-top: 10px;
}
.card-error {
  color: #b40431;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 13px;
}
.checkout-card-wrapper {
  width: 100%;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 5px;
}
.checkout-submit-button-wrapper {
  padding-top: 15px;
  text-align: left;
}
.checkout-submit-button {
  border-color: #045fb4;
  border-width: 1px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 5px;
  padding: 4px 15px;
  font-size: 14px;
  color: #2e2e2e;
}
.checkout-submit-button:hover {
  background-color: #045fb4;
  color: #fff;
}
