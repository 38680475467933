.sftp-list-files-divider {
  margin-top: 10px;
  margin-bottom: 0px;
  border-top: 1px solid #e0e0e0;
  width: 100%;
}
.actions-parent-col {
}
.actions-col {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.sftp-list-details-top-row {
  height: 30px;
}
.sftp-list-details-top-row-details-col {
  width: calc(100% - 100px);
  display: inline-block;
}
.sftp-list-details-top-row-actions-col {
  width: 100px;
  display: inline-block;
  text-align: right;
}
.selected-text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.up-parent-btn {
  background-color: transparent;
  border-width: 0px;
  font-size: 14px;
}
.sftp-list-files-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}
.sftp-list-files-list-item {
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e0e0e0;
  padding: 10px 0px;
}
.sftp-list-files-details-col {
  width: calc(100% - 100px);
  display: inline-block;
}
.sftp-list-files-actions-col {
  width: 100px;
  display: inline-block;
}
.sftp-list-files-checkbox {
  margin-right: 20px;
  width: 15px;
  height: 15px;
}
.sftp-list-files-icon {
  margin-right: 10px;
  font-size: 20px;
}
.sftp-list-files-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
.sftp-list-files-item-btn {
  background-color: transparent;
  border-width: 0px;
}
.sftp-list-files-actions-btn {
  background-color: transparent;
  border-width: 0px;
  margin-right: 10px;
}
.sftp-list-files-actions-btn .spinner-border {
  border-width: 2px;
  width: 15px;
  height: 15px;
}
