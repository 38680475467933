.create-price-list-panel {
  display: inline-block;
  width: 100%;
  padding: 15px 15px;
  margin-left: 10px;
  border: #e5e5e5 1px solid;
  border-radius: 5px;
}
.create-price-list-title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  vertical-align: middle;
}
.create-price-list-steps {
  font-size: 14px;
  font-weight: 500;
  color: #9b9b9b;
  vertical-align: middle;
  margin-bottom: 10px;
}
.create-price-list-next-wrapper {
  width: 100%;
  text-align: right;
}
.create-price-list-review p {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  vertical-align: middle;
  margin-bottom: 5px;
}
