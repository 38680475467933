.sftp-connection-wrapper {
    padding: 10px 0px;
    padding-bottom: 20px;
    max-width: 400px;
}
.sftp-edit-btn {
    margin-left: 10px;
    border-width: 0px;
    border-style: none;
    padding: 0px;
    background-color: transparent;
    text-decoration: underline;
}
.edit-sftp-connection-host-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}