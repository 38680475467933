.settings-subsection {
  margin-top: 5px;
  margin-bottom: 20px;
}
.settings-subsection h4 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
.settings-subsection-actions {
  padding: 10px 0px;
}
.settings-subsection-action-btn {
  margin-right: 10px;
  background-color: #f0f0f0;
  border-width: 0px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
}
.settings-subsection-action-btn:hover {
  background-color: #e0e0e0;
}
.add-settings-modal {
  padding: 15px 15px;
}
.add-settings-modal-title {
  font-size: 16px;

  margin-bottom: 15px;
}
.add-settings-modal-btn-wrapper {
  padding-top: 15px;
}
/* --- Add options --- */
.add-options-title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.add-options-new-wrapper {
  width: 100%;
  display: flex;
}
.add-options-new-btn {
  width: 30px;
  height: 42px;
  background-color: #f0f0f0;
  border-width: 0px;
}
.add-options-new-btn:hover {
  background-color: #e0e0e0;
}
.add-options-list-wrapper {
  width: 100%;
  padding-bottom: 10px;
}
.add-options-list-item {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  display: inline-block;
  margin-right: 5px;
}
.add-options-list-item button {
  border-width: 0px;
  font-size: 12px;
  border-radius: 5px;
}
