.line-graph-selector-top-col {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.line-graph-selector-tabs-col {
  display: flex;
}
.line-graph-selector-date-range-col {
  display: flex;
  align-items: center;
}
.line-graph-selector {
  display: inline-block;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: transparent;
  padding: 3px 10px;
  font-size: 12px;
  color: #7d7d7d;
}
.line-graph-selector-current {
  font-size: 12px;
  background-color: rgba(0, 128, 128, 0.1);
  color: #3f80ea;
  border-color: #3f80ea;
}
.line-graph-date-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 12px;
  color: #7d7d7d;
}
.line-graph-date-input input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  color: #7d7d7d;
}
.line-graph-date-input-between {
  margin: 0 5px;
}
.line-graph-date-input-between label {
  font-size: 12px;
  color: #7d7d7d;
}
