.questionnaire-option {
    display: flex;
    align-items: center;
    padding: 8px 0px;
}
.questionnare-option-icon-col {
    padding-right: 10px;
}
.questionnaire-option-selected {
    color: #088A08;
}
.questionnaire-option-loading {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 0px;
}

.questionnaire-option-loading-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 50%;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
    margin-right: 10px;
}
.questionnaire-option-loading-text {
    width: 100%;
    max-width: 400px;
    height: 24px;
    border-radius: 5px;
    background: linear-gradient(110deg, #e6e6e6 8%, #e6e6e6 18%, #dfdfdf 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
}