.onboarding-apps-section {
    margin-top: 10px;
    margin-bottom: 30px;
}
.onboarding-apps-section h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}
.onboarding-app-listing {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
}
.onboarding-app-listing-img-col {
    text-align: center;
    width: 80px;
}
.onboarding-app-listing-img-col img {
    width: 100%;
}
.onboarding-app-listing-details {
    padding-left: 20px;
    width: calc(100% - 80px);

}
.onboarding-app-listing-details .app-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.onboarding-app-listing-details .app-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}
