.pending-invites-wrapper {
  border-radius: 5px;
  outline: none;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  padding: 10px 20px;
}
.pending-invites-wrapper h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}
.pending-invites-wrapper .subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
.pending-invites-list {
  margin-top: 10px;
}
.pending-invite-item {
  border-bottom: 1px solid #d8d8d8;
  padding: 5px 0px;
}
.pending-invite-img-col {
  width: 50px;
  display: inline-block;
}
.pending-invite-img-col img {
  width: 30px;
  height: 30px;
}
.pending-invite-details-col {
  display: inline-block;
  width: calc(100% - 90px - 30px);
}
.pending-invite-actions-col {
  display: inline-block;
  width: 60px;
  text-align: right;
}
.pending-invite-action-btn {
  background-color: transparent;
  border-width: 0px;
  padding: 0px;
}
.pending-invite-action-btn i {
  font-size: 24px;
  color: #666;
}
