.modal-outter-wrapper {
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
}
.modal-inner-wrapper {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.modal-panel-wrapper {
  width: 100%;
  
  margin-left: auto;
  margin-right: auto;
}
.panel-medium {
  max-width: 500px;
}
.panel-large {
  max-width: 800px;
}
.panel-small {
  max-width: 300px;
}