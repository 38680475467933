.add-default-processes-and-schemas-wrapper {
  padding-top: 10px;
}
.add-default-processes-and-schemas-top-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.add-default-processes-and-schemas-top-row .if-statement {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #424242;
}
.add-default-processes-and-schemas-top-row .if-then {
  font-size: 16px;
  font-weight: 500;
  color: #424242;
  margin-left: 10px;
}
.add-default-processes-and-schemas-top-row .if-input-wrapper {
  border-width: 0px;
  border-bottom-width: 2px;
  border-color: #bdbdbd;
  border-style: solid;
}
.add-default-processes-and-schemas-top-row .if-input-wrapper .if-input {
  width: 100px;
  outline: none;
  border-width: 0px;
  font-size: 16px;
  color: #2e2e2e;
}
.add-default-processes-and-schemas-text {
  font-size: 16px;
  font-weight: 500;
  color: #424242;
  margin-bottom: 10px;
}
.add-default-processes-and-schemas-badge {
  margin-left: 10px;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #f5f5f5;
}
.add-default-processes-and-schemas-btn {
  background-color: #f0f0f0;
  border-width: 0px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
}
.add-default-processes-and-schemas-remove-btn {
  border-width: 0px;
  background-color: transparent;
  font-size: 12px;
}
.add-default-processes-and-schemas-select {
  border-width: 0px;
  background-color: transparent;
  outline: none;
}
