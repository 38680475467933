.list-actions {
}
.list-actions h3 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
.list-actions-item-wrapper {
  width: 100%;
  border-width: 0px;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: #f0f0f0;
  padding: 10px 20px;
}
.list-actions-step-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}
.list-actions-step-description {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.list-actions-badge-rows {
  display: flex;
}
.list-actions-badge-rows .edit-button {
  margin-right: 10px;
  background-color: #f0f0f0;
  border-width: 0px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
}
.list-actions-badge-rows .edit-button:hover {
  background-color: #e0e0e0;
}
.list-actions-badge-rows .edit-button i {
  margin-right: 5px;
}
.list-actions-type {
  background-color: #f0f0f0;
  border-width: 0px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: capitalize;
}
.list-actions-sub-actions {
  margin-top: 10px;
}
.add-action-button-wrapper {
  margin-top: 20px;
}
.add-action-button {
  background-color: #f0f0f0;
  border-width: 0px;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: capitalize;
  color: #a4a4a4;
}
.add-action-button:hover {
  background-color: #e0e0e0;
}
