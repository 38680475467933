.onboarding-row {
  padding-top: 100px;
  padding-bottom: 100px;
}
.onboarding-panel {
  max-width: 500px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.create-company-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: rgb(26, 31, 54);
  font-size: 24px;
}
.create-company-onboard-btn-wrapper {
  width: 100%;
  text-align: left;
}
.onboarding-completed-indicator {
  width: 100%;
  display: flex;
}
.onboarding-completed-icon-wrapper {
}
.onboarding-completed-icon {
  font-size: 20px;
  margin-right: 0px;
  color: #04b404;
}
.onboarding-completed-indicator p {
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 500;
  color: #04b404;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
.onboarding-pending-associations-loading {
  display: flex;
  justify-content: flex-start;
}
.onboarding-pending-associations-loading .spinner-border {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-width: 2px;
}
.onboarding-pending-associations-loading p {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
