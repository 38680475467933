.connected-with-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin: 20px 0px;
}
.connected-with-wrapper h2 {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
    line-height: 1.6;
}
.connected-with-wrapper .btn {
    margin-right: 10px;
}