.single-object-entry-field {
  width: 100%;
  margin-bottom: 10px;
}
.single-object-entry-field-key {
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 15px;
  display: inline-block;
  padding-right: 10px;
  /* max-width: 100px; */
  vertical-align: top;
}
.single-object-entry-field-value {
  display: inline-block;
}
.single-object-entry-field-value-row {
  display: flex;
  flex-wrap: wrap;
}
.single-object-entry-field-value-details-col {
}
.single-object-entry-field-value-edit-col {
  padding-left: 10px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.single-object-entry-field-value-edit-col button {
  border-width: 0px;
  background-color: transparent;
}
.data-type-badge {
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 400;
  color: #4a4a4a;
  background-color: #e6e6e6;
  border-radius: 5px;
  margin-right: 10px;
  text-transform: capitalize;
  display: inline-block;
}
.data-type-badge-main {
  background-color: #e6e6e6;
}
.data-type-badge-list {
  background-color: #e6e6e6;
  display: inline-block;
}
.field-optional {
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 400;
  color: #4a4a4a;
  background-color: #e6e6e6;
  border-radius: 5px;
  margin-right: 10px;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 5px;
}
.object-alert {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  background-color: #ffd6d6;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}
.object-alert-icon-col {
  text-align: center;
}
.object-alert-icon-col .bi {
  font-size: 14px;
  color: #4a4a4a;
}
.object-alert-text-col {
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
}
.field-path {
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 0px;
}
.field-empty-path {
  font-size: 16px;
  font-weight: 400;
  color: #8a8888;
  margin-bottom: 0px;
  font-style: italic;
}
.edit-list-btn {
  border-width: 0px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 12px;
}
.edit-list-btn .bi {
  font-size: 12px;
  color: #4a4a4a;
  margin-right: 5px;
}
.field-size {
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 5px;
}
