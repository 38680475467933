.edi-demo-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}
.edi-demo-about {
    margin-bottom: 10px;
    font-size: 20px;
}
.edi-demo-step-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.edi-demo-syncing-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
}
.edi-demo-syncing-wrapper .spinner-border {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-width: 2px;
}
.edi-demo-syncing-text {
    font-weight: 500;
}