.support-questionaire-card {
  width: 100%;
  padding: 20px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 600px;
  margin-top: 10px;
}
.support-questionaire-card-next-steps {
  font-size: 16px;
  font-weight: 500;
  color: #2e2e2e;
  font-family: 'Roboto', sans-serif;
  margin: 20px 0px;
  text-align: center;
}
.support-questionaire-card-question {
  font-size: 16px;
  font-weight: 500;
  color: #2e2e2e;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}
.support-questionaire-buttons {
  margin-top: 10px;
  text-align: center;
}
.support-questionaire-button {
  background-color: #e0e0e0;
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 5px;
  border-width: 0px;
  cursor: pointer;
  margin: 0px 5px;
}
.support-questionaire-button-yes:hover {
  background-color: #088a29;
  color: #fff;
}
