.settings-section-title {
  font-size: 16px;
}
.settings-section-divider {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-bottom: 25px;
}
.settings-section-title-subtext {
  font-size: 14px;
  color: #a4a4a4;
  margin-bottom: 10px;
  font-weight: 500;
}

/* Ship to Locations */
.flag {
  font-size: 20px;
  margin-right: 5px;
  top: 4px;
}
.remove-country-btn {
  background-color: transparent;
  border-width: 0px;
  font-size: 12px;
  vertical-align: top;
  color: #848484;
}
.remove-country-badge {
  background-color: #f0f0f0;
  color: #a4a4a4;
  font-size: 12px;
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  
}
.remove-country-inner-text {
  vertical-align: top;
  position: relative;
  top: 4px
}