.sftp-connection-details-item {
  width: 100%;
  padding: 10px 0px;
}
.sftp-connection-details-col {
  display: inline-block;
  width: 100%;
  max-width: 400px;
}
.sftp-connection-input-col {
  display: inline-block;
  width: 100%;
  max-width: 400px;
}
.sftp-connetino-details-label {
  font-size: 16px;
  font-weight: 600;
  color: #2e2e2e;
}
.sftp-connection-details-description {
  font-size: 14px;
  color: #5f5f5f;
}
.sftp-connection-metadata-wrapper {
  width: 100%;
  padding: 10px 0px;
}
.sftp-connection-metadata-wrapper p {
  font-size: 12px;
  color: #5f5f5f;
}
.create-creds-btn {
  background-color: #d8d8d8;
  border: none;
  color: #2e2e2e;
  padding: 3px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}
