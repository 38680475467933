.install-sync-events-processes-item {
  padding: 10px 0;
  /* border-bottom: 1px solid #e5e5e5; */
}
.install-sync-events-processes-item h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.install-sync-events-processes-action-item {
  display: flex;
  padding: 10px 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px 15px;
}
.install-sync-events-for-processes-item-icon-col {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin-right: 10px;
}
.install-sync-events-for-processes-item-icon-success {
  background-color: rgba(50, 168, 82, 0.2);
}
.install-sync-events-for-processes-item-icon-failed {
  background-color: rgba(252, 23, 3, 0.2);
}
.install-sync-events-for-processes-item-icon {
  width: 50px;
  text-align: center;
}
.install-sync-events-for-processes-item-icon-col .bi {
  font-size: 24px;
  color: #333;
}
.install-sync-events-for-processes-item-icon-col .bi-check {
  color: #28a745;
}
.install-sync-events-for-processes-item-icon-col .bi-x {
  color: #dc3545;
}
.install-sync-events-for-processes-item-details-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.install-sync-events-for-processes-item-details-col .title {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
.show-state-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.show-state-col {
  display: flex;
  align-items: center;
  width: 50%;
}
.sub-action-activities-wrapper {
  width: 100%;
  margin-top: 20px;
}
.sub-action-activities-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
