.account-setup-section {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.account-setup-section h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}
.account-setup-section .text-muted {
    margin-bottom: 10px;
}

.add-new-store-section {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.add-new-store-section h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
}
.add-new-store-locked {
    opacity: 0.5;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
}
.add-new-store-locked span {
    margin-left: 10px;
}