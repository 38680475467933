.sub-menu-text {
  width: 100%;
}
.sub-menu-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 10px;
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #f1f1f1;
  color: #045fb4;
}
.sub-menu-link-active {
  border-color: #045fb4;
}
